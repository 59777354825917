import { UTCDate } from '@date-fns/utc';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { type FC, type ReactElement, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import type { UpdateModalDataType, DeployModalDataType, StatusType, UserDataType, ScheduleModalDataType } from './shared/types';
import type { FormByIdAllEnvs } from '../../api/queries/forms/forms.types';
import type { OptionType } from '../../common/types';

import { DeployFormModal } from './components/DeployFormModal';
import { ScheduleFormModal } from './components/ScheduleFormModal';
import { UpdateFormModal } from './components/UpdateFormModal';
import { updateEnvDropDown, getDataFromStorage } from './helpers';
import { useDeploymentManagerStatusTable } from './hooks/useDeploymentManagerStatusTable';
import { useDeploymentManagerTable } from './hooks/useDeploymentManagerTable';
import { useDeployForm } from '../../api/hooks/useDeployForm';
import { useForms } from '../../api/hooks/useForms';
import { useScheduleForm } from '../../api/hooks/useScheduleForm';
import { useSyncFormsData } from '../../api/hooks/useSyncFormData';
import { useTestLatestFormVersions } from '../../api/hooks/useTestLatestFormVersions';
import { useUpdateForm } from '../../api/hooks/useUpdateForm';
import { ApplicationEnvironments } from '../../api/queries/shared/types';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { CommonTable } from '../../components/CommonTable/CommonTable';
import { Header } from '../../components/Header/Header';
import { Page } from '../../components/Page/Page';
import { FormControl } from '../../modules/form/components/FormControl/FormControl';
import { useModalState } from '../../modules/form/hooks/useModalState';
import { useFormByIdAllEnvs } from '../QuestionsPage/hooks/useFormByIdAllEnvs/useFormByIdAllEnvs';
import './grid.css';

const resolver = zodResolver(
  z.object({
    name: z.string().nonempty('Name is required'),
    description: z.string().nonempty('Version is required'),
  }),
);

const priority = ['int1', 'int5', 'uat', 'prod'];

const getHighestEnvironment = (formDataById: FormByIdAllEnvs): string => {
  return [...priority].reverse().find((env) => env in formDataById) || '';
};

const getNextDeployEnvironment = (environments: string[]): string | undefined => {
  const environmentsList = Object.values(ApplicationEnvironments);
  const filteredEnvs = environmentsList.filter((el) => !environments.some((e) => e?.toLowerCase() === el.toLowerCase()));
  return filteredEnvs[0] !== ApplicationEnvironments.prod ? filteredEnvs[0] : undefined;
};

export const DeploymentManager: FC = (): ReactElement => {
  const { formId: formName } = useParams() as { formId: string };
  const { isFetching: isFetchingRecent, data: recentFormData } = useTestLatestFormVersions({ formType: formName });

  const environments = useMemo(
    () =>
      recentFormData
        ? Object.keys(recentFormData).map((item) => ({
            ...recentFormData[item],
            environment: item === 'UAT' ? item.toUpperCase() : item.charAt(0).toUpperCase() + item.slice(1),
          }))
        : [],
    [recentFormData],
  );

  const environementsTable = useDeploymentManagerTable(environments);

  const { control } = useForm({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const { isFetching: isFetchingFormSearchData, data: formSearchData } = useForms({
    formType: formName,
    enabled: Boolean(formName),
    env: 'int1',
  });

  const [disableFormActivity, setDisableFormActivity] = useState(true);
  const [selectedVersion, setSelectedVersion] = useState<OptionType | undefined>({ value: '', label: '' });
  const { isFetching: isFetchingFormByVersion, data: formByVersion } = useFormByIdAllEnvs({ formId: selectedVersion?.value || '' });
  const deployDate = formByVersion?.uat?.deployData?.date ? new UTCDate(formByVersion.uat.deployData.date) : undefined;

  const useDeployFn = {
    onCompleteRequest: () => {
      setDisableFormActivity(false);
    },
  };

  const { mutate: mutateUpdateForm, isLoading: isFetchingUpdateForm } = useUpdateForm();

  let highestEnvironment: string = formByVersion ? getHighestEnvironment(formByVersion) : '';
  const deployedEnvironment = formByVersion ? Object.keys(formByVersion) : [];
  const disableDeployBtn = getNextDeployEnvironment(deployedEnvironment) === undefined;

  highestEnvironment =
    highestEnvironment === 'UAT'
      ? highestEnvironment.toUpperCase()
      : highestEnvironment.charAt(0).toUpperCase() + highestEnvironment.slice(1);

  const statusEnvironements: Array<StatusType> = useMemo(
    () => (formByVersion ? [{ id: '1', status: formByVersion?.int1?.qaStatus || '-', highestEnvironment }] : []),
    [formByVersion],
  );

  const isUatHighest = /uat/i.test(highestEnvironment);

  const statusTable = useDeploymentManagerStatusTable(statusEnvironements);

  const { mutate: mutateSyncForm, isLoading: isLoadingSyncForms } = useSyncFormsData();
  const { mutate: mutateDeployForm, isLoading: isLoadingDeployForm } = useDeployForm(useDeployFn);
  const { mutate: mutateScheduleForm, isLoading: isLoadingScheduleForm } = useScheduleForm(useDeployFn);

  const isLoading =
    isFetchingFormSearchData ||
    isFetchingRecent ||
    isFetchingFormByVersion ||
    isFetchingUpdateForm ||
    isLoadingDeployForm ||
    isLoadingScheduleForm;

  const crumbs = [{ label: 'Forms', path: '/' }, { label: formName, path: `/${formName}` }, { label: 'Deployment Manager' }];

  const isSyncAvailable = ['int5', 'uat'].includes(statusEnvironements[0]?.highestEnvironment?.toLowerCase() || '');

  const getUserData = () => JSON.parse(getDataFromStorage('user', '')) as UserDataType;

  const formUpdateData: UpdateModalDataType = {
    environments: updateEnvDropDown(priority, highestEnvironment),
    selectedVersion: selectedVersion?.label || '',
    formName,
  };

  const {
    open: updateFormOpen,
    isOpen: isUpdateFormOpen,
    close: updateFormClose,
    modalData: updateFormData,
    setModalData: setUpdateFormData,
  } = useModalState<UpdateModalDataType>('updateForm');

  const {
    open: deployFormOpen,
    isOpen: isDeployFormOpen,
    close: deployFormClose,
    modalData: deployFormData,
    setModalData: setDeployFormData,
  } = useModalState<DeployModalDataType>('deployForm');

  const {
    open: scheduleFormOpen,
    isOpen: isScheduleFormOpen,
    close: scheduleFormClose,
  } = useModalState<ScheduleModalDataType>('scheduleForm');

  function submitUpdateForm(formData: UpdateModalDataType | undefined): void {
    setUpdateFormData(formData);

    mutateUpdateForm({
      guid: selectedVersion?.value || '',
      env: formData?.selectedEnvironment,
      isTest: formData?.isTest,
      isLatest: formData?.isLatest,
    });
  }

  function submitDeployForm(formData: DeployModalDataType) {
    setDisableFormActivity(true);
    setDeployFormData(formData);
    mutateDeployForm(formData);
  }

  function openDeployForm() {
    const nextDeployStage = getNextDeployEnvironment(deployedEnvironment);
    const options: DeployModalDataType = {
      deployEnvironment: nextDeployStage ?? '',
      selectedVersion: selectedVersion?.label,
      formName,
      formId: selectedVersion?.value ?? '',
      user: getUserData().user,
    };
    deployFormOpen(options);
  }

  function submitScheduleDeploy(formData: ScheduleModalDataType) {
    const uatQuestionnaireGuid = formByVersion?.uat?.questionnaireDataHref.split('/').slice(-1)[0] as string;
    mutateScheduleForm({
      isLatest: formData.isLatest,
      isTest: formData.isTest,
      deployDate: formData.deployDate,
      uatQuestionnaireGuid,
      userId: getUserData().user,
      formGuid: selectedVersion?.value ?? '',
      reset: Boolean(formByVersion?.uat?.deployData?.date),
    });
  }

  const VERSIONS_OPTIONS: { label: string; value: string }[] = formSearchData?.items.map((form) => ({
    label: form.version,
    value: form.questionSet.guid,
  })) || [{ label: '', value: '' }];

  return (
    <Page id="deployment-management" data-testid="deployment-management" isLoading={isLoading}>
      <Header title={`${formName} DEPLOYMENT MANAGER`} />
      <div className="px-8 mt-8 h-full">
        <div className="w-full h-full md:w-9/10 lg:w-4/5 xl:w-[1300px] mx-auto flex flex-col gap-y-2">
          <Breadcrumb crumbs={crumbs} />
          <div className="flex h-full">
            <div className="w-1/2 h-full pl-[8%] pr-[10%] deployment-management-environment-table">
              <CommonTable dataTestId="deployment-management-environment-table" table={environementsTable} />
            </div>
            <div className="w-1/2 h-full pl-[10%] pr-[10%]">
              <div className="bg-white border deployment-management-status">
                <form>
                  <div className="px-[4%] py-[5%]">
                    <FormControl
                      defaultValue={VERSIONS_OPTIONS[0]}
                      options={VERSIONS_OPTIONS}
                      type="select"
                      name="versions"
                      label="Select Form Version"
                      control={control}
                      onChange={(item) => {
                        setSelectedVersion(item!);
                        setDisableFormActivity(false);
                      }}
                    />
                  </div>
                  <CommonTable dataTestId="deployment-management-qa-status-table" table={statusTable} />
                  <div className="flex flex-col space-y-4 px-[4%] py-[5%]">
                    <button
                      className="qe-btn"
                      data-testid="update-test-latest-form-btn"
                      type="button"
                      onClick={() => updateFormOpen(formUpdateData)}
                      disabled={disableFormActivity}
                    >
                      UPDATE IS TEST/LATEST
                    </button>
                    <button
                      className="qe-btn"
                      data-testid="sync-form-data-btn"
                      type="button"
                      disabled={!isSyncAvailable || isLoadingSyncForms || disableFormActivity}
                      onClick={() =>
                        mutateSyncForm({
                          formId: (selectedVersion && selectedVersion.value) ?? '',
                          highestEnvironment: (statusEnvironements && statusEnvironements[0].highestEnvironment?.toLocaleLowerCase()) ?? '',
                        })
                      }
                    >
                      SYNC FORM DATA
                    </button>
                    <button
                      className="qe-btn fill"
                      data-testid="deploy-form-btn"
                      type="button"
                      onClick={() => (isUatHighest ? scheduleFormOpen() : openDeployForm())}
                      disabled={(!isUatHighest && disableDeployBtn) || !deployedEnvironment?.length || isLoadingDeployForm}
                    >
                      {isUatHighest ? `${formByVersion?.uat?.deployData?.date ? 'RE' : ''}SCHEDULE DEPLOY` : 'DEPLOY'}
                    </button>
                  </div>
                  {isUatHighest && formByVersion?.uat?.deployData?.date && (
                    <div className="px-[4%] pb-4 font-bold">
                      <p>
                        Production Deployment Scheduled for {format(new UTCDate(formByVersion.uat.deployData.date), 'MM/dd/yyyy')} At 7:00
                        PM EST
                      </p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateFormModal
        isOpen={isUpdateFormOpen}
        closeModal={updateFormClose}
        selectedFormData={updateFormData}
        onChange={submitUpdateForm}
      />
      {isDeployFormOpen && (
        <DeployFormModal
          isOpen={isDeployFormOpen}
          closeModal={deployFormClose}
          selectedFormData={deployFormData}
          onChange={submitDeployForm}
        />
      )}
      <ScheduleFormModal
        formName={formName}
        formVersion={selectedVersion?.label ?? ''}
        isOpen={isScheduleFormOpen}
        selectedDate={deployDate}
        closeModal={scheduleFormClose}
        onChange={submitScheduleDeploy}
      />
    </Page>
  );
};
